import React from 'react'
import Logo from '../img/logo_mm_forndo_transparente.png'
import { useLocation } from 'react-router-dom'
const navigation = {
  main: [
    { name: 'Terminos y condiciones', href: '#' },
    { name: 'Política de privacidad', href: '#' },
    { name: 'Política de ventas', href: '#' },
    { name: 'Política de devoluciones', href: '#' },
    { name: 'Contacto', href: '/contacto' },
    { name: 'Nosotros', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=100071593083172',
      icon: (props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
  ],
}
export default function Footer() {
  const { pathname } = useLocation()
  if (pathname === '/ingresar') return null
  if (pathname === '/registro') return null
  if (pathname === '/recuperar') return null
  if (pathname === '/codigo_recuperar') return null
  return (
    <footer className='bg-gray-900'>
      <div className='mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8'>
        <nav
          className='-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12'
          aria-label='Footer'
        >
          {navigation.main.map((item) => (
            <div key={item.name} className='pb-6'>
              <a href={item.href} className='text-sm leading-6 text-gray-300 hover:text-white'>
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className='mt-10 flex justify-center space-x-10'>
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className='text-gray-400 hover:text-gray-500'>
              <span className='sr-only'>{item.name}</span>
              <item.icon className='h-6 w-6' aria-hidden='true' />
            </a>
          ))}
        </div>
        <div className='flex mt-6 justify-center'>
          <img className='h-7' src={Logo} alt='Mercado del motociclista' />
        </div>
        {/* <p className="text-sm leading-6 text-gray-300">
          Somos un taller de servicio y garantías, ademas contamos con una
          amplia variedad en refacciones originales Italika y de las mejores
          marcas.
        </p> */}
        <p className='mt-10 text-center text-xs leading-5 text-gray-500'>
          &copy; 2024 NOMBRE MRDM. Todos los derechos reservados.
        </p>
      </div>
    </footer>
  )
}
