import { createContext, useReducer } from 'react'
import secureLocalStorage from 'react-secure-storage'
import type { AdaResponse, Address, Product, User } from 'adaflex-types'
import AdaAPI from 'adaflex-api' // Importa la clase AdaAPI

export type State = {
  userInfo: AdaResponse | null
  pf: any | null
  cart: {
    shippingAddress: Address | {}
    cartItems: Product[] | []
    shippingPrice?: number
  }
  adaAPI: AdaAPI
}

export type ACTIONTYPE =
  | {
      type: 'CART_ADD_ITEM'
      payload: Product
    }
  | {
      type: 'CART_REMOVE_ITEM'
      payload: Product
    }
  | {
      type: 'USER_SIGNIN'
      payload: AdaResponse
    }
  | {
      type: 'USER_SIGNOUT'
      payload: User
    }
  | {
      type: 'SAVE_SHIPPING_ADDRESS'
      payload: Address
    }

export const Store = createContext<any>({} as any)
const initialState: State = {
  userInfo: secureLocalStorage.getItem('userInfo')
    ? JSON.parse((secureLocalStorage.getItem('userInfo') as string) || '{}')
    : null,
  pf: secureLocalStorage.getItem('pf')
    ? JSON.parse((secureLocalStorage.getItem('pf') as string) || '{}')
    : null,
  cart: {
    shippingAddress: secureLocalStorage.getItem('shippingAddress')
      ? JSON.parse((secureLocalStorage.getItem('shippingAddress') as string) || '{}')
      : {},
    shippingPrice: 300,
    /*paymentMethod: secureLocalStorage.getItem("paymentMethod")
      ? secureLocalStorage.getItem("paymentMethod")
      : "",*/
    cartItems: secureLocalStorage.getItem('cartItems')
      ? JSON.parse((secureLocalStorage.getItem('cartItems') as string) || '[]')
      : [],
  },
  adaAPI: new AdaAPI(process.env.REACT_APP_BASE_URL as string),
}
//Variable reducer
const reducer = (state: State, action: ACTIONTYPE): State => {
  switch (action.type) {
    case 'CART_ADD_ITEM':
      //add to cart
      const newItem = action.payload
      const existItem = state.cart.cartItems.find((item: Product) => item.code === newItem.code)
      const cartItems = existItem
        ? state.cart.cartItems.map((item: Product) =>
            item.code === existItem.code ? newItem : item,
          )
        : [...state.cart.cartItems, newItem]
      secureLocalStorage.setItem('cartItems', JSON.stringify(cartItems))
      return { ...state, cart: { ...state.cart, cartItems } }
    case 'CART_REMOVE_ITEM': {
      const cartItems = state.cart.cartItems.filter(
        (item: Product) => item.code !== action.payload.code,
      )
      secureLocalStorage.setItem('cartItems', JSON.stringify(cartItems))
      return { ...state, cart: { ...state.cart, cartItems } }
    }
    // case 'CART_CLEAR':
    //   return { ...state, cart: { ...state.cart, cartItems: [] } }
    case 'USER_SIGNIN':
      return {
        ...state,
        userInfo: action.payload && action.payload,
      }
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
        cart: {
          cartItems: [],
          shippingAddress: {},
          // paymentMethod: '',
        },
      }
    case 'SAVE_SHIPPING_ADDRESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          shippingAddress: action.payload,
        },
      }
    /*case "SAVE_PAYMENT_METHOD":
      return {
        ...state,
        cart: { ...state.cart, paymentMethod: action.payload },
      };*/
    default:
      return state
  }
}
//Esta es la función context como tal
export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value: any = { state, dispatch }
  return <Store.Provider value={value}>{children}</Store.Provider>
}
