import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import Icono from '../img/logo_mm_forndo_transparente.png'
import { PriceFormat } from 'src/utils/formatPrice'
import { Store } from 'src/Store'
import AdaAPI from 'adaflex-api'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  user: string
  order: string
  status: string
}

type Context = {
  state: {
    adaAPI: AdaAPI
  }
}

export default function InvoiceModal({ open, setOpen, order, status }: Props) {
  const [post, setPost] = React.useState(null)
  const { state }: Context = useContext(Store)
  const { adaAPI } = state
  const { data, error, isLoading } = useQuery(
    ['orders', order],
    () => adaAPI.getOrderDetail(order),
    {
      retry: 3,
      retryDelay: 3000,
      networkMode: 'offlineFirst',
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: true,
    },
  )

  const subTotal = data?.products
    ?.map((product) => parseFloat(product.prec.replace(/,/g, '.')) * parseInt(product.cant))
    .reduce((partialSum, a) => partialSum + a, 0)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6'>
                <div>
                  <div className='bg-adaflex-100 py-2 rounded-md mb-3 flex items-center justify-center'>
                    <img className='h-8 w-auto' src={Icono} alt='' />
                  </div>

                  {status !== 'C' ? (
                    <>
                      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                        <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                      </div>
                    </>
                  ) : (
                    <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
                      <XMarkIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                    </div>
                  )}
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      Ordern #{data?.orderNumber}
                    </Dialog.Title>
                    {status !== 'C' ? (
                      <p>Fecha de compra: {data?.orderDate}</p>
                    ) : (
                      <p>Pedido cancelado</p>
                    )}
                    <div className=''>
                      <h3 className='sr-only'>Your information</h3>

                      <h4 className='sr-only'>Domicilio</h4>
                      <dl className='grid grid-cols-2 gap-x-6 py-10 text-sm'>
                        <div>
                          <dt className='font-medium text-gray-900'>Domicilio de envío</dt>
                          <dd className='mt-2 text-gray-700'>
                            <address className='not-italic'>
                              <span className='block'>{data?.shipName}</span>
                              <span className='block'>
                                {data?.shipInt} {data?.shipExt} {data?.shipStreet},{' '}
                                {data?.shipNeighbor}
                              </span>
                              <span className='block'>
                                {data?.shipCity}, {data?.shipState}, {data?.postCode}
                              </span>
                            </address>
                          </dd>
                        </div>
                        <div>
                          <dt className='font-medium text-gray-900'>Factura</dt>
                          <dd className='mt-2 text-gray-700'>
                            <address className='not-italic'>
                              <span className='block'>{data?.billingName}</span>
                              <span className='block'>{data?.billingNumber}</span>
                            </address>
                          </dd>
                        </div>
                      </dl>

                      <h4 className='sr-only'>Pago</h4>
                      <dl className='grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm'>
                        {/* <div>
                          <dt className='font-medium text-gray-900'>Método de pago</dt>
                          <dd className='mt-2 text-gray-700'>
                            <p className='capitalize'>
                              {payment.data?.payment_type_id.replace(/_/g, ' ')}
                            </p>
                            <p className='capitalize'>{payment.data?.payment_method_id}</p>
                            <p>
                              <span aria-hidden='true'>••••</span>
                              <span className='sr-only'>Termina en </span>
                              {payment.data?.card.last_four_digits}
                            </p>
                          </dd>
                        </div> */}
                        <div>
                          <dt className='font-medium text-gray-900'>Método de envío</dt>
                          <dd className='mt-2 text-gray-700'>
                            {/* <p>DHL</p> */}
                            <p className='mb-2'>
                              Número de rastreo: <br /> {data?.trackingGuide}
                            </p>
                            <p className='mb-2'>
                              Estado del pedido: <br /> {data?.orderStatus}
                            </p>
                            <p className='mb-2'>
                              Fecha de envío: <br /> {data?.deliveryDate}
                            </p>
                            <p className='mb-2'>
                              Proveedor del servicio: <br /> {data?.deliveryProvider}
                            </p>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className='mt-2'>
                      <div className='relative overflow-x-auto'>
                        <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                          <thead className='text-xs text-gray-100 uppercase bg-adaflex-100 dark:bg-gray-700 dark:text-gray-400'>
                            <tr>
                              <th scope='col' className='px-6 py-3'>
                                Producto
                              </th>
                              <th scope='col' className='px-6 py-3'>
                                Precio
                              </th>
                              <th scope='col' className='px-6 py-3'>
                                Cantidad
                              </th>
                              <th scope='col' className='px-6 py-3'>
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.products?.map((product) => (
                              // eslint-disable-next-line react/jsx-key
                              <tr
                                key={product?.prod}
                                className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                              >
                                <th
                                  scope='row'
                                  className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                >
                                  <a href={`producto/${product.prod}`}>{product?.desc}</a>
                                </th>
                                <td className='px-6 py-4'>
                                  ${PriceFormat(Number(product?.prec?.replace(/,/g, '.')))}
                                </td>
                                <td className='px-6 py-4'> {product?.cant}</td>
                                <td className='px-6 py-4'>
                                  $
                                  {PriceFormat(
                                    parseFloat(product?.prec?.replace(/,/g, '.')) *
                                      parseFloat(product?.cant),
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className='flex justify-end mr-6'>
                          <div className='mt-4 grid grid-cols-2 gap-2 w-2/4 justify-items-end'>
                            <div>Subtotal:</div>
                            <div>${PriceFormat(subTotal)}</div>
                            <div>Envío:</div>
                            <div>${data?.shipCost?.replace(/,/g, '.') ?? 0}</div>
                            <div>Total:</div>
                            <div>${PriceFormat(subTotal)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-5 flex justify-end sm:mt-6'>
                  {/* <button
                    type='button'
                    className='mt-6 mr-6 flex w-full items-center justify-center rounded-md border border-blue-300 bg-adaflex-100 px-4 py-2 text-sm font-medium text-gray-100 shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto'
                    onClick={generatePDF}
                  >
                    Descargar PDF
                  </button> */}
                  <button
                    type='button'
                    className='mt-6 mr-6 flex w-full items-center justify-center rounded-md border border-blue-300 bg-adaflex-100 px-4 py-2 text-sm font-medium text-gray-100 shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto'
                    onClick={() => setOpen(false)}
                  >
                    Volver
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
