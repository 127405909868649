import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Store } from '../Store'
import CheckoutSteps from '../components/Steps'

import type { ACTIONTYPE } from '../Store'
import type { Address, Shipping, User } from 'adaflex-types'
import { classNames } from 'src/utils/object'
import { zodResolver } from '@hookform/resolvers/zod'
import { type ShippingUserFormValues, shippingnUserFormSchema } from '../schemas/shippingSchema'
import secureLocalStorage from 'react-secure-storage'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import AdaAPI from 'adaflex-api'

type InitialState = {
  userInfo: User
  pf: {
    username: string
    password: string
  }
  cart: {
    shippingAddress: Address
  }
  adaAPI: AdaAPI
}

export default function ShippingAddress() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {
    state,
    dispatch: ctxDispatch,
  }: { state: InitialState; dispatch: React.Dispatch<ACTIONTYPE> } = useContext(Store)
  const {
    pf,
    userInfo,
    cart: { shippingAddress },
    adaAPI,
  } = state

  const fullName = `${userInfo.nombre} ${userInfo.apellidopat} ${userInfo.apellidomat}`
  const infoEnvio = {
    nombreEnvio: fullName,
    telefonoEnvio: shippingAddress.telefonoEnvio,
    cpEnvio: shippingAddress.cpEnvio,
    calleEnvio: shippingAddress.calleEnvio,
    numExtEnvio: shippingAddress.numExtEnvio,
    numIntEnvio: shippingAddress.numIntEnvio,
    coloniaEnvio: shippingAddress.coloniaEnvio,
    municipioEnvio: shippingAddress.municipioEnvio,
    estadoEnvio: shippingAddress.estadoEnvio,
    paisEnvio: 'México',
    ciudadEnvio: shippingAddress.ciudadEnvio,
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ShippingUserFormValues>({
    resolver: zodResolver(shippingnUserFormSchema),

    defaultValues: {
      ...infoEnvio,
    },
  })

  const {
    mutateAsync,
    isLoading,
    isError,
    data: response,
    error,
  } = useMutation(
    // adaAPI.createShipping
    async (body: Shipping) => {
      return await new AdaAPI(process.env.REACT_APP_BASE_URL as string).createShipping(body)
    },
    {
      onSuccess: (data, variables) => {
        if (data.status === 'Success') {
          ctxDispatch({
            type: 'SAVE_SHIPPING_ADDRESS',
            payload: {
              ...variables,
            },
          })
          secureLocalStorage.setItem(
            'shippingAddress',
            JSON.stringify({
              ...variables,
            }),
          )
          navigate('/orden')
        }
        void queryClient.invalidateQueries(['shipping'])
      },
    },
  )

  const submitHandler = async (data: ShippingUserFormValues) => {
    const { ...infoEnvio } = data
    const { username, password } = pf
    await mutateAsync({
      username,
      password,
      lada: '52',
      ...infoEnvio,
    })
  }

  function getError(field: keyof ShippingUserFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  if (isError) toast.error('No se pudo guardar su dirección de envío')
  else toast.clearWaitingQueue()

  if (response?.status === 'Error') {
    toast.error(response.message)
  }
  if (!userInfo) {
    navigate('/ingreso')
  }

  return (
    <>
      <Helmet>
        <title>Dirección de envío</title>
      </Helmet>

      <div
        aria-hidden='true'
        className='absolute inset-x-0 z-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:h-[25vh]'
      >
        <div className='absolute inset-0 flex'>
          <div className='h-full w-1/2' style={{ backgroundColor: '#0a527b' }} />
          <div className='h-full w-1/2' style={{ backgroundColor: '#065d8c' }} />
        </div>
        <div className='relative flex justify-center'>
          <svg className='shrink-0' width={1750} height={308} viewBox='0 0 1750 308'>
            <path d='M284.161 308H1465.84L875.001 182.413 284.161 308z' fill='#0369a1' />
            <path d='M1465.84 308L16.816 0H1750v308h-284.16z' fill='#065d8c' />
            <path d='M1733.19 0L284.161 308H0V0h1733.19z' fill='#110356' />
            <path d='M875.001 182.413L1733.19 0H16.816l858.185 182.413z' fill='#110356' />
          </svg>
        </div>
      </div>

      <header className='relative py-10'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <h1 className='text-3xl font-bold tracking-tight text-white'>Información de envío</h1>
        </div>
      </header>
      <main className='relative'>
        <div className='mx-auto max-w-[75vw] 2xl:max-w-[50vw] px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16'>
          <CheckoutSteps value='envio' />
          <div className='overflow-hidden p-10 rounded-lg bg-white shadow'>
            <form className='mt-4' onSubmit={handleSubmit(submitHandler)}>
              <div className='mb-6'>
                <label
                  htmlFor='nombreEnvio'
                  className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                >
                  Nombre completo<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  id='nombreEnvio'
                  {...register('nombreEnvio')}
                  // placeholder='Ej: cliente@ejemplo.com'
                  className={classNames(
                    getError('nombreEnvio')
                      ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                      : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                    'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                  )}
                />
                {getError('nombreEnvio') && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {getError('nombreEnvio')}
                  </p>
                )}
              </div>
              <div className='mb-6'>
                <label
                  htmlFor='calleEnvio'
                  className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                >
                  Calle<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  id='calleEnvio'
                  {...register('calleEnvio')}
                  className={classNames(
                    getError('calleEnvio')
                      ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                      : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                    'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                  )}
                  placeholder='Nombre de la calle'
                />
                {getError('calleEnvio') && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {getError('calleEnvio')}
                  </p>
                )}
              </div>
              <div className='mb-6 grid gap-6 md:grid-cols-2'>
                <div>
                  <label
                    htmlFor='numExtEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Número exterior<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='numExtEnvio'
                    {...register('numExtEnvio')}
                    className={classNames(
                      getError('numExtEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                    placeholder='Ej: 101'
                    inputMode='numeric'
                    pattern='[0-9]*'
                  />
                  {getError('numExtEnvio') && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                      {getError('numExtEnvio')}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='numIntEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Número interior
                  </label>
                  <input
                    type='text'
                    id='numIntEnvio'
                    {...register('numIntEnvio')}
                    className={classNames(
                      getError('numIntEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                    placeholder='Ej: 12'
                    inputMode='numeric'
                    pattern='[0-9]*'
                  />
                  {getError('numIntEnvio') && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                      {getError('numIntEnvio')}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='coloniaEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Colonia<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='coloniaEnvio'
                    {...register('coloniaEnvio')}
                    className={classNames(
                      getError('coloniaEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                    placeholder='Ej: Jardines del valle'
                  />
                  {getError('coloniaEnvio') && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                      {getError('coloniaEnvio')}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='municipioEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Municipio<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='municipioEnvio'
                    {...register('municipioEnvio')}
                    className={classNames(
                      getError('municipioEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('municipioEnvio') && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                      {getError('municipioEnvio')}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='ciudadEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Ciudad<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='ciudadEnvio'
                    {...register('ciudadEnvio')}
                    className={classNames(
                      getError('ciudadEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('ciudadEnvio') && (
                    <p className='mt-2 text-sm text-red-600'>{getError('ciudadEnvio')}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='cpEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Código Postal<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='cpEnvio'
                    {...register('cpEnvio')}
                    className={classNames(
                      getError('cpEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                    placeholder='Ej: 45105'
                    inputMode='numeric'
                    pattern='[0-9]*'
                  />
                  {getError('cpEnvio') && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                      {getError('cpEnvio')}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='telefonoEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Número de teléfono<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='telefonoEnvio'
                    {...register('telefonoEnvio')}
                    className={classNames(
                      getError('telefonoEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                    placeholder='Ej: 33-45-16-2555'
                    pattern='[0-9]*'
                    inputMode='numeric'
                  />
                  {getError('telefonoEnvio') && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                      {getError('telefonoEnvio')}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='estadoEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Estado<span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    aria-label='Seleccionar estado'
                    id='estadoEnvio'
                    {...register('estadoEnvio')}
                    className={classNames(
                      getError('estadoEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  >
                    <option value='' disabled={true}>
                      Estado de la república
                    </option>
                    <option value='Aguascalientes'>Aguascalientes</option>
                    <option value='Baja California'>Baja California Norte</option>
                    <option value='Baja California Sur'>Baja California Sur</option>
                    <option value='Campeche'>Campeche</option>
                    <option value='Chiapas'>Chiapas</option>
                    <option value='Chihuahua'>Chihuahua</option>
                    <option value='CDMX'>Ciudad de México</option>
                    <option value='Coahuila'>Coahuila</option>
                    <option value='Colima'>Colima</option>
                    <option value='Durango'>Durango</option>
                    <option value='Estado de México'>Estado de México</option>
                    <option value='Guanajuato'>Guanajuato</option>
                    <option value='Guerrero'>Guerrero</option>
                    <option value='Hidalgo'>Hidalgo</option>
                    <option value='Jalisco'>Jalisco</option>
                    <option value='Michoacán'>Michoacán</option>
                    <option value='Morelos'>Morelos</option>
                    <option value='Nayarit'>Nayarit</option>
                    <option value='Nuevo León'>Nuevo León</option>
                    <option value='Oaxaca'>Oaxaca</option>
                    <option value='Puebla'>Puebla</option>
                    <option value='Querétaro'>Querétaro</option>
                    <option value='Quintana Roo'>Quintana Roo</option>
                    <option value='San Luis Potosí'>San Luis Potosí</option>
                    <option value='Sinaloa'>Sinaloa</option>
                    <option value='Sonora'>Sonora</option>
                    <option value='Tabasco'>Tabasco</option>
                    <option value='Tamaulipas'>Tamaulipas</option>
                    <option value='Tlaxcala'>Tlaxcala</option>
                    <option value='Veracruz'>Veracruz</option>
                    <option value='Yucatán'>Yucatán</option>
                    <option value='Zacatecas'>Zacatecas</option>
                  </select>
                </div>
                <div className='sr-only'>
                  <label
                    htmlFor='paisEnvio'
                    className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                    País<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    id='paisEnvio'
                    {...register('paisEnvio')}
                    className={classNames(
                      getError('paisEnvio')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                    value='México'
                    readOnly
                    disabled
                  />
                </div>
              </div>
              {/* <div className='mb-4'>
                <label
                  htmlFor='infoAdicional'
                  className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                >
                  Información adicional
                </label>
                <textarea
                  id='infoAdicional'
                  rows={4}
                  className='block w-full rounded-md border-0 py-1.5  ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                  placeholder='Escriba información adicional del domicilio de envío...'
                />
              </div> */}
              {!isLoading ? (
                <button
                  type='submit'
                  className='w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto'
                >
                  Continuar
                </button>
              ) : (
                <button
                  type='submit'
                  className='w-full rounded-lg bg-blue-700 px-4 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto'
                >
                  <svg
                    aria-hidden='true'
                    role='status'
                    className='inline w-4 h-4 mr-3 text-white animate-spin'
                    viewBox='0 0 100 101'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                      fill='#E5E7EB'
                    />
                    <path
                      d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                      fill='currentColor'
                    />
                  </svg>
                  Cargando...
                </button>
              )}
              {/* <Link
                to='/orden'
                className='w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto'
              >
                Continuar
              </Link> */}
            </form>
          </div>
        </div>
      </main>
    </>
  )
}
