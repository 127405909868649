// import Spinner from "react-bootstrap/Spinner";
import React from 'react'
import RingLoader from 'react-spinners/RingLoader'
import SyncLoader from 'react-spinners/SyncLoader'

type Props = {
  value?: string
  color?: string
}

export function LoadingBox(props: Props) {
  switch (props.value) {
    case 'adaflex':
      return (
        <div>
          <RingLoader size={100} color='#110356'>
            <span className='visually-hidden'>Cargando...</span>
          </RingLoader>
        </div>
      )
    case 'mrdm':
      return (
        <div>
          <SyncLoader size={30} color={props.color ? props.color : '#110356'}>
            <span className='visually-hidden'>Cargando...</span>
          </SyncLoader>
        </div>
      )
    default:
      return (
        <div>
          <RingLoader size={100} color='#110356'>
            <span className='visually-hidden'>Cargando...</span>
          </RingLoader>
        </div>
      )
  }
}
