import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  BillingUserFormValues,
  billingFormSchema
} from '../schemas/billingSchema'
import { classNames } from '../utils/object'
import { UseMutationResult } from '@tanstack/react-query'
import { DevTool } from '@hookform/devtools'
import { toast } from 'react-toastify'
import { ResponseCFDI, Billing } from 'adaflex-types'

type Props = {
  order: string
  affect: UseMutationResult<ResponseCFDI, unknown, Billing, unknown>
}

export const BillingForm = (props: Props) => {
  const { order, affect } = props
  const { mutateAsync, isError, isLoading, data: response } = affect
  const [isGeneric, setIsGeneric] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<BillingUserFormValues>({
    resolver: zodResolver(billingFormSchema)
  })

  function getError(field: keyof BillingUserFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.toUpperCase()
    if (value === 'XAXX010101000' || value === 'XEXX010101000') {
      setIsGeneric(true)
    } else {
      setIsGeneric(false)
    }
  }

  const submitHandler = async (data: BillingUserFormValues) => {
    const { ...infoFactura } = data
    await mutateAsync({
      noPedido: order,
      ...infoFactura
    })
  }
  /**
   * Importante agregar como se efectuó el pago. tarjeta de credito o debito, esto se consigue desde mercado pago
   *
   */

  if (isError) toast.error('No se pudo generar su factura')
  else toast.clearWaitingQueue()

  if (response?.status === 'Error') {
    toast.error(response.message)
  }

  return (
    <div className='mx-auto max-w-[75vw] py-10  2xl:max-w-[50vw]'>
      <div className='max-w-xl mb-10'>
        <h1 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
          Facturación del pedido #{order}
        </h1>
        <p className='mt-1 text-sm text-gray-500'>
          Solicite la factura del pedido seleccionado.
          {/* Check the status of recent orders, manage returns, and download invoices. */}
        </p>
      </div>
      <div className='grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3'>
        <div className='px-4 sm:px-0'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Datos para facturación
          </h2>
          <p className='mt-1 text-sm leading-6 text-gray-600'>
            Llene el formulario acorde a los datos que se piden para la
            facturación de su pedido
          </p>
        </div>

        <form
          onSubmit={handleSubmit(submitHandler)}
          className='bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'
        >
          <div className='px-4 py-6 sm:p-8'>
            <div className='mb-6'>
              <label
                htmlFor='nombreFiscal'
                className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
              >
                Nombre fiscal
              </label>
              <input
                type='text'
                id='nombreFiscal'
                {...register('nombreFiscal')}
                className={classNames(
                  getError('nombreFiscal')
                    ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                    : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                  'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                )}
                disabled={isGeneric}
              />
              {getError('nombreFiscal') && (
                <p className='mt-2 text-sm text-red-600'>
                  {getError('nombreFiscal')}
                </p>
              )}
            </div>

            <div className='grid mb-4 max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='sm:col-span-4'>
                <label
                  htmlFor='rfc'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  RFC
                </label>
                <div className='mt-2'>
                  <input
                    id='rfc'
                    type='text'
                    {...register('rfc', { onChange: handleChange })}
                    className={classNames(
                      getError('rfc')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5 uppercase ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                    )}
                  />
                  {getError('rfc') && (
                    <p className='mt-2 text-sm text-red-600'>
                      {getError('rfc')}
                    </p>
                  )}
                </div>
                {isGeneric && (
                  <p className='text-sm ml-2 text-red-600'>
                    RCF genérico, no se puede facturar.
                  </p>
                )}
              </div>
            </div>

            <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='sm:col-span-4'>
                <label
                  htmlFor='regimenFiscal'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Régimen fiscal
                </label>
                <div className='mt-2'>
                  <select
                    id='regimenFiscal'
                    disabled={isGeneric}
                    {...register('regimenFiscal')}
                    className={classNames(
                      getError('regimenFiscal')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                    )}
                  >
                    <option value='601'>
                      601: General de Ley Personas Morales
                    </option>
                    <option value='603'>
                      603: Personas Morales con Fines no Lucrativos
                    </option>
                    <option value='605'>
                      605: Sueldos y Salarios e Ingresos Asimilados a Salarios
                    </option>
                    <option value='606'>606: Arrendamiento</option>
                    <option value='607'>
                      607: Régimen de Enajenación o Adquisición de Bienes
                    </option>
                    <option value='608'>608: Demás ingresos</option>
                    <option>
                      610: Residentes en el Extranjero sin Establecimiento
                      Permanente en México
                    </option>
                    <option value='611'>
                      611: Ingresos por Dividendos (socios y accionistas)
                    </option>
                    <option value='612'>
                      612: Personas Físicas con Actividades Empresariales y
                      Profesionales
                    </option>
                    <option value='614'>614: Ingresos por intereses</option>
                    <option value='615'>
                      615: Régimen de los ingresos por obtención de premios
                    </option>
                    <option value='616'>616: Sin obligaciones fiscales</option>
                    <option value='620'>
                      620: Sociedades Cooperativas de Producción que optan por
                      diferir sus ingresos
                    </option>
                    <option value='621'>621: Incorporación Fiscal</option>
                    <option value='622'>
                      622: Actividades Agrícolas, Ganaderas, Silvícolas y
                      Pesqueras
                    </option>
                    <option value='623'>
                      623: Opcional para Grupos de Sociedades
                    </option>
                    <option value='624'>624: Coordinados</option>
                    <option value='625'>
                      625: Régimen de las Actividades Empresariales con ingresos
                      a través de Plataformas Tecnológicas
                    </option>
                    <option value='626'>
                      626: Régimen Simplificado de Confianza
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='mt-4 sm:col-span-4'>
                <label
                  htmlFor='formaPago'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Método de pago
                </label>
                <div className='mt-2'>
                  <select
                    id='formaPago'
                    disabled={isGeneric}
                    {...register('formaPago')}
                    className={classNames(
                      getError('formaPago')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                    )}
                  >
                    <option value='04'>04: Tarjeta de crédito</option>
                    <option value='28'>28: Tarjeta de débito</option>
                  </select>
                </div>
                <div className='mt-4 sm:col-span-4'>
                  <label
                    htmlFor='usoCfdi'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    CFDI
                  </label>
                  <div className='mt-2'>
                    <select
                      id='usoCfdi'
                      disabled={isGeneric}
                      {...register('usoCfdi')}
                      className={classNames(
                        getError('usoCfdi')
                          ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                          : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                        'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                      )}
                    >
                      <option value='G01'>G01:Adquisición de mercancías</option>
                      <option value='G02'>
                        G02:Devoluciones, descuentos o bonificaciones.
                      </option>
                      <option value='G03'>G03: Gastos en general.</option>
                      <option value='I01'>I01:Construcciones.</option>
                      <option value='I02'>
                        I02:Mobiliario y equipo de oficina por inversiones.
                      </option>
                      <option value='I03'>I03: Equipo de transporte.</option>
                      <option value='I04'>
                        I04: Equipo de computo y accesorios.
                      </option>
                      <option value='I05'>
                        I05: Dados, troqueles, moldes, matrices y herramental.
                      </option>
                      <option value='I06'>
                        I06: Comunicaciones telefónicas.
                      </option>
                      <option value='I07'>
                        I07: Comunicaciones satelitales.
                      </option>
                      <option value='I08'>I08:Otra maquinaria y equipo.</option>
                      <option value='CP01'>CP01: Pagos</option>
                      <option value='CN01'>CN01: Nómina</option>
                      <option value='D01'>
                        D01: Honorarios médicos, dentales y gastos
                        hospitalarios.
                      </option>
                      <option value='D02'>
                        D02: Gastos médicos por incapacidad o discapacidad.
                      </option>
                      <option value='D03'>D03: Gastos funerales.</option>
                      <option value='D04'>D04: Donativos.</option>
                      <option value='D05'>
                        D05: Intereses reales efectivamente pagados por créditos
                        hipotecarios (casa habitación).
                      </option>
                      <option value='D06'>
                        D06: Aportaciones voluntarias al SAR.
                      </option>
                      <option value='D07'>
                        D07: Primas por seguros de gastos médicos.
                      </option>
                      <option value='D08'>
                        D08: Gastos de transportación escolar obligatoria.
                      </option>
                      <option value='D09'>
                        D09: Depósitos en cuentas para el ahorro, primas que
                        tengan como base planes de pensiones.
                      </option>
                      <option value='D10'>
                        D10: Pagos por servicios educativos (colegiaturas).
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='sm:col-span-2 sm:col-start-1'>
                <label
                  htmlFor='cp'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Código postal
                </label>
                <div className='mt-2'>
                  <input
                    disabled={isGeneric}
                    type='text'
                    {...register('cp')}
                    className={classNames(
                      getError('cp')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                    )}
                  />
                  {getError('cp') && (
                    <p className='mt-2 text-sm text-red-600'>
                      {getError('cp')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8'>
            <a
              href='/historial'
              type='button'
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              Volver
            </a>
            {isGeneric ? (
              <button
                type='submit'
                className='rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm '
              >
                Facturar
              </button>
            ) : (
              <div>
                {!isLoading ? (
                  <button
                    type='submit'
                    className='rounded-md bg-adaflex-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                    Facturar
                  </button>
                ) : (
                  <button
                    disabled
                    className='inline-flex justify-center rounded-md bg-adaflex-100 px-3 py-2 text-sm font-semibold text-white shadow-sm'
                  >
                    <svg
                      aria-hidden='true'
                      role='status'
                      className='inline w-4 h-4 mr-3 text-white animate-spin'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='#E5E7EB'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentColor'
                      />
                    </svg>
                    Cargando
                  </button>
                )}
              </div>
            )}
          </div>
          <DevTool control={control} />
        </form>
      </div>
    </div>
  )
}
