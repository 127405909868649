import { Link } from 'react-router-dom'

export default function CheckoutSteps({ value }: { value?: string }) {
  const steps = [
    { id: '01', name: 'Revisar carrito', href: '/carrito', status: 'complete' },
    {
      id: '02',
      name: 'Datos de domicilio',
      href: '/envio',
      status: value === 'envio' ? 'current' : 'complete',
    },
    { id: '03', name: 'Pago', href: '', status: value === 'envio' ? 'upcoming' : 'current' },
  ]
  return (
    <nav aria-label='Progress'>
      <ol role='list' className='space-y-4 z-50 md:flex md:space-x-8 md:space-y-0'>
        {steps.map((step) => (
          <li key={step.name} className='md:flex-1'>
            {step.status === 'complete' ? (
              <Link
                to={step.href}
                className='group flex flex-col border-l-4 border-sky-600 py-2 pl-4 hover:border-sky-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
              >
                {/* <span className='text-sm font-medium text-white group-hover:text-sky-800'>
                  {step.id}
                </span> */}
                <span className='text-sm font-medium text-white'>{step.name}</span>
              </Link>
            ) : step.status === 'current' ? (
              <Link
                to={step.href}
                className='flex flex-col border-l-4 border-sky-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
                aria-current='step'
              >
                {/* <span className='text-sm font-medium text-white'>{step.id}</span> */}
                <span className='text-sm font-medium text-white'>{step.name}</span>
              </Link>
            ) : (
              <Link
                to={step.href}
                className='group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
              >
                {/* <span className='text-sm font-medium text-white group-hover:text-gray-700'>
                  {step.id}
                </span> */}
                <span className='text-sm font-medium text-white'>{step.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
