import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'
import { classNames } from 'src/utils/object'
import { Link, useLocation, useParams } from 'react-router-dom'
import { LoadingBox, Pagination } from 'lib'
import MessageBox from 'src/components/MessageBox'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { Store } from 'src/Store'
import AdaAPI from 'adaflex-api'

type Context = {
  state: {
    adaAPI: AdaAPI
  }
}

export default function BrandProducts() {
  const breadcrumbs = [{ id: 1, name: 'Inicio', href: '/' }]
  const params = useParams()
  const { brandName } = params
  const { state }: Context = useContext(Store)
  const { adaAPI } = state
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const productPage = Number(searchParams.get('pagina')) || 1
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [marcaFilter, setmarcaFilter] = useState<string[]>([])
  const [lineFilter, setLineFilter] = useState<string[]>([])
  const [typeFilter, setTypeFilter] = useState<string[]>([])
  const productoQty = 9
  const { data, isLoading, error, isFetching, isFetched } = useQuery(
    ['products'],
    () => adaAPI.getProductsBrand(brandName as string, productoQty, productPage),
    {
      retry: 3,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const group = data?.lines?.map((g) => {
    const obj = {
      grupo: g.linea,
      cantidad: g.cantidad,
      id: g.linea,
    }
    return obj
  })

  // const sortOptions = [
  //   { order: 'normal', name: 'Más Relevante', href: '#', current: order === 'normal' },
  //   { order: 'low', name: 'Precio: Bajo a Alto', href: '#', current: order === 'low' },
  //   { order: 'high', name: 'Precio: Alto a Bajo', href: '#', current: order === 'high' },
  // ]
  const filters = [
    {
      id: 'categoria',
      name: 'Categorías relacionadas',
      options: group,
    },
  ]
  if (isLoading || isFetching) {
    return (
      <span className='mt-3 flex justify-center items-center mb-3 h-full min-h-screen'>
        <LoadingBox color='#075985' value='mrdm' />
      </span>
    )
  }
  if (error instanceof Error) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{error.message}</MessageBox>
      </span>
    )
  }

  if (data?.status === 'Error') {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{data.message}</MessageBox>
      </span>
    )
  }

  function sortValues(value: string | null) {
    switch (value) {
      case 'low':
        return data?.products?.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      case 'high':
        return data?.products?.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      case 'normal':
        return data?.products?.sort((a, b) => parseFloat(a.codigo) - parseFloat(b.codigo))
      default:
        return data?.products
    }
  }

  function handleFilter(e: React.ChangeEvent<HTMLInputElement>, id: string) {
    if (id === 'brand') {
      const isValue = e.target.value
      if (!marcaFilter.includes(isValue)) {
        return setmarcaFilter([...marcaFilter, isValue])
      } else {
        return setmarcaFilter((marcaFilter) =>
          marcaFilter.filter((data) => data !== e.target.value),
        )
      }
    }
    if (id === 'line') {
      const isValue = e.target.value
      if (!lineFilter.includes(isValue)) {
        return setLineFilter([...lineFilter, isValue])
      } else {
        return setLineFilter((lineFilter) => lineFilter.filter((data) => data !== e.target.value))
      }
    }
    if (id === 'type') {
      const isValue = e.target.value
      if (!typeFilter.includes(isValue)) {
        return setTypeFilter([...typeFilter, isValue])
      } else {
        return setTypeFilter((typeFilter) => typeFilter.filter((data) => data !== e.target.value))
      }
    }
  }

  // function filterData() {
  //   const brand = sortValues(order)?.filter(({ brand }) => marcaFilter.includes(brand)) || []
  //   const line = sortValues(order)?.filter(({ line }) => lineFilter.includes(line)) || []
  //   const type = sortValues(order)?.filter(({ type }) => typeFilter.includes(type)) || []

  //   const arr = brand.concat(line, type)
  //   const filters = arr.filter((v, i, a) => a.findIndex((v2) => v2.codigo === v.codigo) === i)
  //   if (filters.length > 0) return filters
  //   else return sortValues(order)
  // }

  return (
    <>
      <div className='bg-white'>
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as='div' className='relative z-40 lg:hidden' onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter='transition-opacity ease-linear duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-linear duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black bg-opacity-25' />
              </Transition.Child>

              <div className='fixed inset-0 z-40 flex'>
                <Transition.Child
                  as={Fragment}
                  enter='transition ease-in-out duration-300 transform'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transition ease-in-out duration-300 transform'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <Dialog.Panel className='relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl'>
                    <div className='flex items-center justify-between px-4'>
                      <h2 className='text-lg font-medium text-gray-900'>Filtros</h2>
                      <button
                        type='button'
                        className='-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500'
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className='sr-only'>Close menu</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>

                    {/* Filters */}
                    <div className='mt-4'>
                      {filters.map((section) => (
                        <Disclosure
                          as='div'
                          key={section.name}
                          className='border-t border-gray-200 pb-4 pt-4'
                        >
                          {({ open }) => (
                            <fieldset>
                              <legend className='w-full px-2'>
                                <Disclosure.Button className='flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500'>
                                  <span className='text-sm font-medium text-gray-900'>
                                    {section.name}
                                  </span>
                                  <span className='ml-6 flex h-7 items-center'>
                                    <ChevronDownIcon
                                      className={classNames(
                                        open ? '-rotate-180' : 'rotate-0',
                                        'h-5 w-5 transform',
                                      )}
                                      aria-hidden='true'
                                    />
                                  </span>
                                </Disclosure.Button>
                              </legend>
                              <Disclosure.Panel className='px-4 pb-2 pt-4'>
                                <div className='space-y-6'>
                                  {section?.options?.map((option, optionIdx) => {
                                    if (option.grupo !== '')
                                      return (
                                        <div key={option.id} className='flex items-center'>
                                          <Link
                                            to={`/categoria/${option.id}`}
                                            className='ml-3 text-sm text-gray-500'
                                          >
                                            {option.grupo}
                                          </Link>
                                        </div>
                                      )
                                  })}
                                </div>
                              </Disclosure.Panel>
                            </fieldset>
                          )}
                        </Disclosure>
                      ))}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div className='border-b border-gray-200'>
            <nav aria-label='Breadcrumb' className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <ol role='list' className='flex items-center space-x-4 py-4'>
                {breadcrumbs.map((breadcrumb) => (
                  <li key={breadcrumb.id}>
                    <div className='flex items-center'>
                      <Link to={breadcrumb.href} className='mr-4 text-sm font-medium text-gray-900'>
                        {breadcrumb.name}
                      </Link>
                      <svg
                        viewBox='0 0 6 20'
                        aria-hidden='true'
                        className='h-5 w-auto text-gray-300'
                      >
                        <path
                          d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                ))}
                <li className='text-sm'>
                  <Link
                    to='#'
                    aria-current='page'
                    className='font-medium text-gray-500 hover:text-gray-600'
                  >
                    Marca {brandName}
                  </Link>
                </li>
              </ol>
            </nav>
          </div>

          <main className='mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8'>
            <div className='flex items-baseline justify-between border-b border-gray-200 pb-4 pt-24'>
              {/* <div> */}
              <h1 className='text-4xl font-bold tracking-tight text-gray-900'>
                Productos de la brand &quot;{brandName}&quot;
              </h1>
              {/* <p className='text-base text-gray-500'>Nuestro catálogo de nuevos productos.</p> */}
              {/* </div> */}
              <div className='flex items-center'>
                {/* <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900'>
                      Ordenar
                      <ChevronDownIcon
                        className='-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <div className='py-1'>
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <button
                                onClick={() => setOrder(option.order)}
                                className={classNames(
                                  option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm w-full',
                                )}
                              >
                                {option.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu> */}

                {/* <button
                  type='button'
                  className='-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7'
                >
                  <span className='sr-only'>View grid</span>
                  <Squares2X2Icon className='h-5 w-5' aria-hidden='true' />
                </button> */}
                <button
                  type='button'
                  className='-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden'
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className='sr-only'>Filtros</span>
                  <FunnelIcon className='h-5 w-5' aria-hidden='true' />
                </button>
              </div>
            </div>

            <div className='pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4'>
              <aside>
                <h2 className='sr-only'>Filtros</h2>

                <button
                  type='button'
                  className='inline-flex items-center lg:hidden'
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className='text-sm font-medium text-gray-700'>Filtros</span>
                  <PlusIcon
                    className='ml-1 h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                </button>

                <div className='hidden lg:block'>
                  <form className='space-y-10 divide-y divide-gray-200'>
                    {filters.map((section, sectionIdx) => (
                      <div key={section.name} className={sectionIdx === 0 ? '' : 'pt-10'}>
                        <fieldset>
                          <legend className='block text-sm font-medium text-gray-900'>
                            {section.name}
                          </legend>
                          <div className='space-y-3 pt-6'>
                            {section.options?.map((option, optionIdx) => {
                              if (option.grupo !== '')
                                return (
                                  <div key={option.id} className='flex items-center'>
                                    <Link
                                      to={`/categoria/${option.id}`}
                                      className='ml-3 decoration-inherit text-sm text-gray-600'
                                    >
                                      {option.grupo}
                                    </Link>
                                  </div>
                                )
                            })}
                          </div>
                        </fieldset>
                      </div>
                    ))}
                  </form>
                </div>
              </aside>

              <section
                aria-labelledby='product-heading'
                className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'
              >
                <h2 id='product-heading' className='sr-only'>
                  Productos
                </h2>

                <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3'>
                  {data?.products?.map((product, index) => {
                    return (
                      <motion.div
                        key={product.codigo}
                        className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
                        initial={{ opacity: 0 }}
                        animate={isFetched ? { opacity: 1 } : {}} // Animación al mostrar los productos
                        transition={{ duration: 0.5, delay: isFetched ? index * 0.1 : 0 }} // Retraso progresivo para cada producto
                      >
                        <div className='aspect-h-4 aspect-w-3 border-b-2 bg-transparent sm:aspect-none group-hover:opacity-75 '>
                          <div className='h-full w-full object-cover object-center sm:h-full sm:w-full'>
                            {product.BImagen !== '' ? (
                              <div className='aspect-h-4 aspect-w-3 bg-transparent sm:aspect-none group-hover:opacity-75 sm:h-96'>
                                <img
                                  src={`data:image/jpg;base64,${product.BImagen}`}
                                  alt={product.codigo}
                                  className='h-full w-full object-contain object-center sm:h-full sm:w-full'
                                />
                              </div>
                            ) : (
                              <div className='grid place-content-center aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96'>
                                <div className='text-3xl'>Sin imagen</div>
                              </div>
                            )}
                            {/* <img
                            src={`data:image/jpg;base64,${product.BImagen}`}
                            alt={product.codigo}
                          /> */}
                          </div>
                        </div>
                        <div className='flex flex-1 flex-col space-y-2 p-4'>
                          <h3 className='text-sm font-medium text-gray-900'>
                            <Link reloadDocument to={`/productos/${product.codigo}`}>
                              <span aria-hidden='true' className='absolute inset-0' />
                              {product.articulo}
                            </Link>
                          </h3>
                          <p className='text-sm text-gray-500'>Código: {product.codigo}</p>
                          <div className='flex flex-1 flex-col justify-end'>
                            <p className='text-sm text-gray-500'>{product.marca}</p>
                            <p className='text-base font-medium text-green-700'>
                              {' '}
                              $
                              <strong>
                                {Number(product.price?.replace(/,/g, '.'))
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>{' '}
                              mxn
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    )
                  })}
                </div>
                <div className='mt-5'>
                  <Pagination query={brandName} data={data} productoQty={productoQty} />
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
