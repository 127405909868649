import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useContext, useEffect, useState } from 'react'
import { Store } from '../Store'
import { toast } from 'react-toastify'
import Icono from '../img/logo_mm_forndo_transparente.png'
import ImageBack from '../components/img/wall-6958508_1280.jpg'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { type RegisterUserFormValues, registerUserFormSchema } from '../schemas/registerSchema'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { classNames } from '../utils/object'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import secureLocalStorage from 'react-secure-storage'

import type { ACTIONTYPE } from '../Store'
import type { SignIn, User, UserCreate } from 'adaflex-types'
import AdaAPI from 'adaflex-api'

type Context = {
  state: {
    userInfo: User
    adaAPI: AdaAPI
  }
  dispatch: React.Dispatch<ACTIONTYPE>
}

const SignUp = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl ? redirectInUrl : '/'

  const [isRevealPwd, setIsRevealPwd] = useState(false)
  const [isRevealConf, setIsRevealConf] = useState(false)
  const queryClient = useQueryClient()
  const { state, dispatch: ctxDispatch }: Context = useContext(Store)
  const { userInfo, adaAPI } = state
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<RegisterUserFormValues>({
    resolver: zodResolver(registerUserFormSchema),
  })

  const mutation = useMutation(
    // createUser
    async (body: UserCreate) => {
      return await new AdaAPI(process.env.REACT_APP_BASE_URL as string).createUser(body)
    },
    {
      onSuccess: async (data, variables) => {
        if (data.status === 'Success')
          await userData.mutateAsync({
            username: variables.username as string,
            password: variables.password as string,
          })
        void queryClient.invalidateQueries(['users'])
      },
      onError: (error) => {
        toast.error(error instanceof Error)
      },
    },
  )

  const userData = useMutation(
    // adaAPI.signIn
    async (body: SignIn) => {
      return await new AdaAPI(process.env.REACT_APP_BASE_URL as string).signIn(body)
    },
    {
      onSuccess: (data, variables) => {
        if (data.status === 'Success' && variables) {
          const { username, password } = variables
          ctxDispatch({ type: 'USER_SIGNIN', payload: data })
          secureLocalStorage.setItem('userInfo', JSON.stringify(data))
          secureLocalStorage.setItem('pf', JSON.stringify({ username, password }))
          navigate(redirect || '/')
          location.reload()
        }
        void queryClient.invalidateQueries(['users'])
      },
      onError: (error) => {
        toast.error(error instanceof Error)
      },
    },
  )

  const submitHandler = async (data: RegisterUserFormValues) => {
    const { username, password, nombre, apellidoPat, apellidoMat, telefono } = data
    await mutation.mutateAsync({
      username,
      password,
      nombre,
      apellidoPat,
      apellidoMat: apellidoMat || '',
      telefono,
      lada: '52',
    })
  }

  function getError(field: keyof RegisterUserFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  function passwordValidation(value: string) {
    const password = watch().password
    if (password)
      switch (value) {
        case 'length':
          return password.length >= 8
        case 'number':
          return /\d/.test(password)
        case 'case':
          return /[A-Z]/.test(password)
        case 'special':
          return /[@$!%*?&#.:]/.test(password)
        default:
          return
      }
  }

  if (mutation.data?.status === 'Error') {
    toast.error(mutation.data.message)
    toast.clearWaitingQueue()
  }

  if (userData.data?.status === 'Error') {
    toast.error(userData.data.message)
    toast.clearWaitingQueue()
  }

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [navigate, redirect, userInfo])
  return (
    <>
      <Helmet>
        <title>Registro</title>
      </Helmet>
      <div className='flex'>
        <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <Link to='/'>
                <img className='h-16 w-auto' src={Icono} alt='Your Company' />
              </Link>
              <h2 className='mt-6 text-3xl font-bold tracking-tight text-gray-900'>
                Registre una cuenta
              </h2>
            </div>

            <div className='mt-8'>
              <div className='mt-6'>
                <form onSubmit={handleSubmit(submitHandler)} method='POST' className='space-y-6'>
                  <div className='border-b border-gray-900/10 pb-12'>
                    <h2 className='text-base font-semibold leading-7 text-gray-900'>
                      Información del usuario
                    </h2>
                    <p className='mt-1 text-sm leading-6 text-gray-600'>
                      Su correo electrónico será su identificación.
                    </p>

                    <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                      <div className='sm:col-span-4'>
                        <label
                          htmlFor='username'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Correo electrónico
                        </label>
                        <div className=' relative mt-2'>
                          <input
                            id='username'
                            {...register('username')}
                            type='email'
                            autoComplete='email'
                            className={classNames(
                              getError('username')
                                ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                              'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                            )}
                          />
                          {getError('username') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('username') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('username')}
                          </p>
                        )}
                      </div>
                      <div className='sm:col-span-4'>
                        <label
                          htmlFor='nombre'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Nombre(s)
                        </label>
                        <div className=' relative mt-2'>
                          <input
                            id='nombre'
                            {...register('nombre')}
                            type='text'
                            className={classNames(
                              getError('nombre')
                                ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                              'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                            )}
                          />
                          {getError('nombre') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('nombre') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('nombre')}
                          </p>
                        )}
                      </div>
                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='apellidoPat'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Apellido paterno
                        </label>
                        <div className=' relative mt-2'>
                          <input
                            id='apellidoPat'
                            {...register('apellidoPat')}
                            type='text'
                            className={classNames(
                              getError('apellidoPat')
                                ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                              'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                            )}
                          />
                          {getError('apellidoPat') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('apellidoPat') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('apellidoPat')}
                          </p>
                        )}
                      </div>

                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='apellidoMat'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Apellido materno
                        </label>
                        <div className=' relative mt-2'>
                          <input
                            type='text'
                            {...register('apellidoMat')}
                            id='apellidoMat'
                            className={classNames(
                              getError('apellidoMat')
                                ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                              'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                            )}
                          />
                          {getError('apellidoMat') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('apellidoMat') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('apellidoMat')}
                          </p>
                        )}
                      </div>

                      <div className='col-span-full'>
                        <label
                          htmlFor='telefono'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Teléfono
                        </label>
                        <div className=' relative mt-2'>
                          <input
                            type='text'
                            {...register('telefono')}
                            id='telefono'
                            className={classNames(
                              getError('telefono')
                                ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                              'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                            )}
                          />
                          {getError('telefono') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('telefono') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('telefono')}
                          </p>
                        )}
                      </div>
                      <div className='col-span-full'>
                        <label
                          htmlFor='apellidoPat'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Contraseña
                        </label>
                        <div className=' flex mt-2'>
                          <div className='relative flex grow items-stretch focus-within:z-10'>
                            <input
                              type={isRevealPwd ? 'text' : 'password'}
                              id='password'
                              {...register('password')}
                              className={classNames(
                                getError('password')
                                  ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                  : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                                'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                              )}
                            />
                          </div>
                          <button
                            type='button'
                            onClick={() => setIsRevealPwd((prevState) => !prevState)}
                            className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                          >
                            {isRevealPwd ? (
                              <EyeIcon
                                className='-ml-0.5 h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            ) : (
                              <EyeSlashIcon
                                className='-ml-0.5 h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            )}
                          </button>
                          {getError('password') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('password') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('password')}
                          </p>
                        )}
                        <div>
                          <p className='my-2 text-sm font-medium leading-6 text-gray-900'>
                            Requisitos de contraseña:
                          </p>
                          <ul className='max-w-md space-y-1 text-gray-500 text-sm list-inside dark:text-gray-400'>
                            <li className='flex items-center'>
                              {passwordValidation('length') ? (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              )}
                              Al menos 8 caracteres
                            </li>
                            <li className='flex items-center'>
                              {passwordValidation('number') ? (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              )}
                              Al menos un número
                            </li>
                            <li className='flex items-center'>
                              {passwordValidation('case') ? (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              )}
                              Al menos una letra mayúscula
                            </li>
                            <li className='flex items-center'>
                              {passwordValidation('special') ? (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                                    clipRule='evenodd'
                                  ></path>
                                </svg>
                              )}
                              Incluya al menos un caracter especial, e.j., ! @ # ?
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className='col-span-full'>
                        <label
                          htmlFor='confirmPassword'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Confirmar contraseña
                        </label>
                        <div className=' flex mt-2'>
                          <div className='relative flex grow items-stretch focus-within:z-10'>
                            <input
                              id='confirmPassword'
                              type={isRevealConf ? 'text' : 'password'}
                              {...register('confirmPassword')}
                              className={classNames(
                                getError('confirmPassword')
                                  ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                                  : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                                'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                              )}
                            />
                          </div>
                          <button
                            type='button'
                            onClick={() => setIsRevealConf((prevState) => !prevState)}
                            className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                          >
                            {isRevealConf ? (
                              <EyeIcon
                                className='-ml-0.5 h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            ) : (
                              <EyeSlashIcon
                                className='-ml-0.5 h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            )}
                          </button>
                          {getError('confirmPassword') && (
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                              <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </div>
                        {getError('confirmPassword') && (
                          <p className='mt-2 text-sm text-red-600' id='email-error'>
                            {getError('confirmPassword')}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='flex items-center justify-between'>
                    <div className='text-sm'>
                      <Link
                        to={`/ingresar?redirect=${redirect}`}
                        className='font-medium text-sky-500 hover:text-sky-500'
                      >
                        Ya tiene una cuenta?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type='submit'
                      className='flex w-full justify-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white  hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
                    >
                      Registrarse
                    </button>
                    <a
                      href='/'
                      className='flex w-full justify-center mt-2 rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white  hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
                    >
                      Volver
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='relative hidden w-0 flex-1 lg:block'>
          <img className='absolute inset-0 h-full w-full object-cover' src={ImageBack} alt='' />
        </div>
      </div>
    </>
  )
}

export default SignUp
