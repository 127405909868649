import React from "react";

const NotFound = () => {
  return (
    <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <p className="text-base font-semibold leading-8 text-sky-600">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Página no encontrada
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        Una disculpa, no pudimos encontrar la página que busca.
      </p>
      <div className="mt-10">
        <a
          href="/"
          className="text-sm font-semibold leading-7 text-sky-600"
        >
          <span aria-hidden="true">&larr;</span> Volver a inicio
        </a>
      </div>
    </main>
  );
};

export default NotFound;
