import { BillingForm } from 'lib'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import AdaAPI from 'adaflex-api'
import { Billing } from 'adaflex-types'

export default function BillingPage() {
  const queryClient = useQueryClient()
  const params = useParams()
  const { order } = params

  const affect = useMutation(
    async (body: Billing) => {
      return await new AdaAPI(process.env.REACT_APP_BASE_URL as string).generarCFDI(body)
    },
    {
      onSuccess: (data) => {
        toast.success(data.message)
        toast.clearWaitingQueue()
        void queryClient.invalidateQueries(['bill'])
      },
      onError: () => {
        toast.error('No se pudo actualizar la información')
        toast.clearWaitingQueue()
      },
    },
  )
  return <BillingForm affect={affect} order={order || ''} />
}
